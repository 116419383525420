import React, { createContext } from 'react'
import { useState } from 'react'
import jwt_decode from "jwt-decode";
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const CourseContext = createContext();

export let UserDataContext=createContext()

export default function UserContext({ children }) {
  let navigate=useNavigate()

  let [userDataObject, setUserDataObject] = useState(null);
  const [courseInfos, setCourseInfos] = useState({});

  let decodeToken = () => {
    let token = localStorage.getItem("Token");
    let decode_data = jwt_decode(token);

    setUserDataObject(decode_data);
    localStorage.setItem("User_id", decode_data.user_id);
  };

  let logOut = () => {
    localStorage.removeItem("Token");
    localStorage.removeItem("User_id");
    setUserDataObject(null);
    navigate("/login")
    
  };

  const saveCourseInfos = (data) => {
    setCourseInfos(data);
  };

  useEffect(() => {
    if (localStorage.getItem("Token")) {
      decodeToken();
    }
  }, []);
  let Base_url="https://api.professional-management-academy.com/api"
  // let Base_url="http://127.0.0.1:8000/api"

  return (
    <UserDataContext.Provider
      value={{Base_url:Base_url ,logOut: logOut, decodeToken: decodeToken, userDataObject: userDataObject, setUserDataObject: setUserDataObject }}
    >
      <CourseContext.Provider value={{ courseInfos, setCourseInfos, saveCourseInfos }}>
        {children}
      </CourseContext.Provider>
    </UserDataContext.Provider>
  );
}