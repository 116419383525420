import React, { useState, useEffect, useContext } from 'react';
import Navbar_two from '../Navbar/Navbar2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import AnimatedPage from '../AnimatedPage/AnimatedPage';
import { UserDataContext } from '../UserContext/UserContext'


export default function AllLectuers() {
  const url = window.location.href;
  const parts = url.split('/');
  const id = parts[4];

  let {Base_url}=useContext(UserDataContext)
  
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(`${Base_url}/courses/${id}`);
        const course_data = await response.data;
        setData(course_data);
        setLoading(false);
        console.log(course_data);
      } catch (error) {
        //
      }
    }
    fetchData();
  }, [id]);


  
  const parsedLessons = data?.lessons?.map((lesson) => {
    const match = lesson.name.match(/Lec (\d+)/); // Extract the number after "Lec"
    const lectureNumber = match ? parseInt(match[1]) : 0; // Convert the number to integer
    return {
      lesson,
      lectureNumber,
    };
  });
  const sortedLessons = parsedLessons?.sort((a, b) => a.lectureNumber - b.lectureNumber);




  let [numbers, setNumbers] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);

  let token = localStorage.getItem('Token');
  const isLogged = !(token === null || token === 'undefined' || token === '');

  const navigate = useNavigate();

  return (
    <>
      {isLogged ? (
        <AnimatedPage>
          <Navbar_two />

          <h3 className='text-center my-5'>The Course Lessons</h3>
          <div className='container'>
            {loading ? (
              <div className='accordion p-2 accordion-borderless placeholder-glow' id='accordionFlushExampleX'>
                {numbers.map((number) => {
                  return (
                    <div className='placeholder w-100' key={number}>
                      <div className='accordion-item '>
                        <h2 className='accordion-header'></h2>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className='accordion p-2 accordion-borderless' id='accordionFlushExampleX'>
                {sortedLessons?.map(({ id, lesson }) => {
                  const collapseId = `flush-collapse${lesson.id}`;
                  const headingId = `flush-heading${lesson.id}`;
                    return (
                    <div className='accordion-item' key={lesson.id}>
                      <h2 className='accordion-header' id={headingId}>
                        <button
                          className='accordion-button text-dark'
                          type='button'
                          data-mdb-toggle='collapse'
                          data-mdb-target={`#${collapseId}`}
                          aria-expanded='true'
                          aria-controls={collapseId}
                        >
                          Lesson {lesson.name}
                        </button>
                      </h2>

                      <div
                        id={collapseId}
                        className='accordion-collapse collapse hide'
                        aria-labelledby={headingId}
                        data-mdb-parent='#accordionFlushExampleX'
                      >
                        <div className='accordion-body'>
                          <ul>
                            <li className='my-2'>
                              <FontAwesomeIcon icon={faInfoCircle} className='text-primary mx-2'></FontAwesomeIcon>{' '}
                              <Link to={`/lectuers/${data?.id}/${lesson.id}`} className='text-muted lectuer-name-vidorpdf'>
                                Start Learn
                              </Link>{' '}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </AnimatedPage>
      ) : (
        navigate('/login')
      )}
    </>
  );
}