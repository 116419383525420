import React, { useState, useEffect, useContext } from 'react';
import Navbar from '../Navbar/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faInfoCircle, faRightLong } from '@fortawesome/free-solid-svg-icons';

import Comments from './Comments';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import AnimatedPage from '../AnimatedPage/AnimatedPage';
import Content from './Content';
import Navbar_two from '../Navbar/Navbar2';
import { UserDataContext } from '../UserContext/UserContext'

export default function Lectuers() {
  let {Base_url}=useContext(UserDataContext)
  const url = window.location.href;
  const parts = url.split('/');
  const id = parts[4];

  let token = localStorage.getItem('Token');
  const isLogged = !(token === null || token === 'undefined' || token === '');

  const navigate = useNavigate();

  const [data, setData] = useState();
  let [loading, setLoading] = useState(true);
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(`${Base_url}/courses/${id}`);
        const course_data = await response.data;
        setData(course_data);
        setLoading(false);
        console.log(data.lessons);
      } catch (error) {
        // Handle error
      }
    }
    fetchData();
  }, [id]);

  let [numbers, setNumbers] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);

  const parsedLessons = data?.lessons?.map((lesson) => {
    const match = lesson.name.match(/Lec (\d+)/); // Extract the number after "Lec"
    const lectureNumber = match ? parseInt(match[1]) : 0; // Convert the number to integer
    return {
      lesson,
      lectureNumber,
    };
  });
  const sortedLessons = parsedLessons?.sort((a, b) => a.lectureNumber - b.lectureNumber);




  return (
    <>
      {isLogged ? (
        <>
          <Navbar_two />
          <AnimatedPage>
            <div className="container lectuer-container my-5">
              <h3 className="text-center">
                {loading ? (
                  <div className="spinner-grow text-secondary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  data.name
                )}
              </h3>

              <div className="row">
                <div className="lectuer-parent container col-12 col-lg-8 ">
                  {!loading && <Content />}
                  {!loading && (
                    <div>
                      <Comments />
                    </div>
                  )}
                </div>

                <div className="topics-parent col-12 col-lg-3 ">
                  <h6 className="text-center fw-bold w-100">
                    <Link to={`/Exam/${data?.name}`} className="btn btn-primary text-light">
                      Start Exam <FontAwesomeIcon icon={faRightLong} />
                    </Link>
                  </h6>

                  {loading ? (
                    <div className="accordion p-2 accordion-borderless placeholder-glow" id="accordionFlushExampleX">
                      {numbers.map((number) => {
                        return (
                          <div className="placeholder w-100" key={number}>
                            <div className="accordion-item ">
                              <h2 className="accordion-header"></h2>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="accordion p-2 accordion-borderless" id="accordionFlushExampleX">
                      {sortedLessons.map(({ id, lesson }) => {
                        const collapseId = `flush-collapse${lesson.id}`;
                        const headingId = `flush-heading${lesson.id}`;

                        return (
                          <div className="accordion-item" key={lesson.id}>
                            <h2 className="accordion-header" id={headingId}>
                              <button
                                className="accordion-button text-dark"
                                type="button"
                                data-mdb-toggle="collapse"
                                data-mdb-target={`#${collapseId}`}
                                aria-expanded="true"
                                aria-controls={collapseId}
                              >
                                Lesson {lesson.name}
                              </button>
                            </h2>

                            <div
                              id={collapseId}
                              className="accordion-collapse collapse hide"
                              aria-labelledby={headingId}
                              data-mdb-parent="#accordionFlushExampleX"
                            >
                              <div className="accordion-body">
                                <ul>
                                  <li className="my-2">
                                    <FontAwesomeIcon icon={faInfoCircle} className="text-primary mx-2"></FontAwesomeIcon>{' '}
                                    <Link to={`/lectuers/${data?.id}/${lesson.id}`} className="text-muted  fw-bold lectuer-name-vidorpdf">
                                      Start Learn
                                    </Link>{' '}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </AnimatedPage>
        </>
      ) : (
        navigate('/login')
      )}
    </>
  );
}