import React, { useState, useEffect } from 'react';
import Navbar from '../../Navbar/Navbar';
import { useNavigate } from 'react-router-dom';
import AboveTarget from './srcs/AboveTarget.png';
import BelowTarget from './srcs/BelowTarget.png';
import NeedImprovement from './srcs/NeedImprovement.png';
import Target from './srcs/Target.png';
import Navbar_two from '../../Navbar/Navbar2';

export default function SeeTheAnswerTable() {
  const [results, setResults] = useState([]);
  const [selectedOption, setSelectedOption] = useState('all');
  const navigate = useNavigate();

  useEffect(() => {
    const storedResults = JSON.parse(sessionStorage.getItem('results'));
    if (storedResults) {
      setResults(storedResults);
    }
  }, []);

  const handleBackClick = () => {
    navigate(-1);
  };

  if (results.length === 0) {
    return <p>No results to display</p>;
  }

  const scoreChecking = (score) => {
    let result;
    switch (true) {
      case score < 40:
        result = 'NeedImprovement';
        break;
      case score < 74:
        result = 'BelowTarget';
        break;
      case score < 85:
        result = 'Target';
        break;
      case score > 85:
        result = 'AboveTarget';
        break;
      default:
        result = 'Outstanding';
    }
    return result;
  };

  const totalQuestions = results.length;
  const correctAnswersCount = results.reduce((count, result) => {
    if (result.is_correct === "Correct") {
      return count + 1;
    }
    return count;
  }, 0);

  
  const correctPercentage = Math.round((correctAnswersCount / totalQuestions) * 100);

  const scoreResult = scoreChecking(correctPercentage);

  let scoreImage;
  switch (scoreResult) {
    case 'NeedImprovement':
      scoreImage = NeedImprovement;
      break;
    case 'BelowTarget':
      scoreImage = BelowTarget;
      break;
    case 'Target':
      scoreImage = Target;
      break;
    case 'AboveTarget':
      scoreImage = AboveTarget;
      break;
    default:
      scoreImage = 'DefaultImage';
  }

  const filteredResults =
    selectedOption === 'correct'
      ? results.filter((result) => result.is_correct === "Correct")
      : selectedOption === 'wrong'
      ? results.filter((result) => result.is_correct === "not Correct")
      : results;

  return (
    
    <>
      <Navbar_two />
      <h2 className="text-center my-2">Result</h2>
      <p className="text-center">
        Percentage of correct answers: <span className="fw-bold">{correctPercentage}%</span>
      </p>

      <div className="container my-5 d-flex justify-content-center">
        <img src={scoreImage} width={'100%'} style={{ maxWidth: '700px' }} className="result-image" />
      </div>

      <hr />
      <h3 className="text-center">Your Answers</h3>

      <br />
      <div className="d-flex justify-content-center mb-3">
        <div className="form-group col-md-4">
          <label htmlFor="answerFilter">Filter by Answer Type:</label>
          <select
            className="form-select search-result bg-dark text-light"
            id="answerFilter"
            value={selectedOption}
            onChange={(e) => setSelectedOption(e.target.value)}
          >
            <option value="all">All</option>
            <option value="correct">Correct</option>
            <option value="wrong">Wrong</option>
          </select>
        </div>
      </div>

      <div className="table-responsive-md">
        <div className='table-parent'>
          <table className="table container text-left my-5 table-bordered">
            <thead className="text-dark">
              <tr className="table-dark">
                <th scope="col">Question</th>
                <th scope="col">User_Answer</th>
                <th scope="col">Correct_Answer</th>
                <th scope="col">isCorrect</th>
                <th scope="col">Explanation</th>
              </tr>
            </thead>
            <tbody className="text-light">
              {filteredResults.map((result, index) => {


                return (
                  <tr key={index} className={result.is_correct === 'Correct' ? 'table-success' : 'table-danger'}>
                    <td>{result.question}</td>
                    <td>{`${result.user_answer} ,  `}</td>
                    <td>{`${result.correctAnswer} , `}</td>
                    <td>{result.is_correct}</td>
                    <td>{result.explanation}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <button type="button" onClick={handleBackClick} className="btn btn-dark mb-3 mx-5">
        Redo The Exam
      </button>
    </>
  );
}