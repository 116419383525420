import React, { useState, useEffect, useContext } from 'react';
import ReactPaginate from 'react-paginate';
import Navbar from "../Navbar/Navbar"
import "./courses.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { faRightLong } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import AnimatedPage from '../AnimatedPage/AnimatedPage'
import PlaceHolder from './placeholder';
// import IMAGE from "./f.jpg"
import IMAGE from "./back.png"
import { UserDataContext } from '../UserContext/UserContext'


function Courses(props) {
    let {Base_url}=useContext(UserDataContext)
    let [loading, setLoading] = useState(true)

    const [list, setList] = useState("");
    const [pageNumber, setPageNumber] = useState(0);
    const DataPerPage = 4;
    const pagesVisited = pageNumber * DataPerPage;

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    let [courses, setCourses] = useState([]);
    let [error, setError] = useState(false);

    let getLessonComments = async () => {
        try {
            setLoading(true);
            let data = await axios.get(`${Base_url}/courses/`);
            let courses_data = data.data;
            setCourses(courses_data);
            setLoading(false)
        } catch (error) {
            console.log(error);
            setError(true);
        } 
    };

    useEffect(() => {
        getLessonComments();
    }, []);

    const filteredData = courses.filter((item) =>
        item.name.toLowerCase().includes(list.toLowerCase())
    );

    const pageCount = Math.ceil(filteredData.length / DataPerPage);

    return (
        <>
            {/* <Navbar /> */}
          <AnimatedPage>
            <div className='pb-5 courses-parent' id="courses" >
                <div className="page-section "data-aos="fade-up" >
                    <div className="container">
                        <div className="row">
                            <div className="sidebar col-md-12 col-lg-5">
                                <div className="sidebar-block">
                                    <h3 className="sidebar-title">Search</h3>
                                    <form action="#" className="search-form my-4">
                                        <div className="form-group" >
                                            <input
                                                type="text"
                                                className="form-outline search-input"
                                                placeholder="Type a keyword To Search"
                                                onChange={(event) => {
                                                    const keyword = event.target.value;
                                                    setList(keyword);
                                                }}
                                            />
                                            <span className="icon mai-search" />
                                        </div>
                                    </form>
                                </div>
                                <div className="sidebar-block" >
                                    <h3 className="sidebar-title">Quick View</h3>
                                    <p>
                                    Welcome to our course search! Whether you're a student, professional, or lifelong learner, our comprehensive catalog offers a wide range of courses across various disciplines. Use the search box below to find your desired course by name. Simply start typing, and our powerful search feature will instantly display relevant results.
                                    </p>
                                </div>
                            </div>
                            {loading ? (
                          <PlaceHolder/>
                            ) : (
                                <div className='col-12 my-3 col-lg-7 card-container' data-aos="fade-right">
                                    {filteredData
                                        .slice(pagesVisited, pagesVisited + DataPerPage)
                                        .map((item) => (
                                            <div className=" col-md-5 col-sm-7 py-2 mx-3" style={{ "width": "320px"}}>
                                            <div className="card-blog" style={{height:"260px"}}>
                                                <div className="header">

                                                    <span  className="post-thumb" >
                                                        <img src={item.image} style={{width:"100%",height:"100%"}}/>
                                                    </span>
                                                </div>
                                                <div className="body">
                                                    <Link to={`/Checkout/${item.id}`}> <p className='px-2 text-center fw-bold text-dark'>{item.name} </p> 
                                                    
                                                    <p className='text-muted'>Enroll Now <FontAwesomeIcon icon={faRightLong}/>  </p> 
                                                    
                                                    </Link>
                                                    </div>
                                            </div>
                                        </div>

                           
                                        ))}
                                </div>
                            )}
                        </div>
                    </div>

                    {courses.length<=4?"":
                  
                  <ReactPaginate
                  previousLabel={"Back"}
                  nextLabel={"Next"}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  containerClassName={"paginationBttns"}
                  previousLinkClassName={"btn-dark btn"}
                  nextLinkClassName={"btn-dark btn"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive "}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
           />
           }
                  
                </div>
            </div>
        </AnimatedPage>
        </>
    );
}

export default Courses;