import React from 'react'

export default function PlaceHolder() {
  return (
    <div className='col-12 my-1 col-lg-7 card-container placeholder-glow'  >
    <div className=" col-md-5 col-sm-7 py-2 mx-3 " style={{ "minWidth": "260px" }}>
    <div className="card-blog">
        <div className="header placeholder">
            <div className="post-category">
    
            </div>
            <span  className="post-thumb" >
                <img />
            </span>
        </div>
        <div className="body">
             <p className='px-2 text-center fw-bold text-dark placeholder'> ssssssssssssssss</p> 
            
            <p className='text-muted placeholder'>Enroll Now  </p> 
            
            </div>
    </div>
</div>

<div className=" col-md-5 col-sm-7 py-2 mx-3 " style={{ "minWidth": "260px" }}>
    <div className="card-blog">
        <div className="header placeholder">
            <div className="post-category">
         
            </div>
            <span  className="post-thumb" >
                <img />
            </span>
        </div>
        <div className="body">
             <p className='px-2 text-center fw-bold text-dark placeholder'> ssssssssssssssss</p> 
            
            <p className='text-muted placeholder'>Enroll Now  </p> 
            
            </div>
    </div>
</div>



</div>  )
}
