import React from 'react'
import { moreInfo } from './Modal';
// Import Swiper styles
import "swiper/css";
import { Pagination  } from "swiper";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";


export default function Instructor_card(props) {
    var widget=3

    function myFunction(x) {        
        if (x.matches) { // If media query matches
            widget=2
        }
    
         else {
            widget=3
    
        }
    
      }
      
      var x = window.matchMedia("(max-width: 860px)")
      myFunction(x) // Call listener function at run time
      x.addListener(myFunction) // Attach listener function on state changes
    
      function myFunction(y) {
        if (x.matches) { // If media query matches
            widget=1
        }
    
         else {     
            widget=3
    
        }
    
      }
      
      var y = window.matchMedia("(max-width: 560px)")
      myFunction(y) // Call listener function at run time
      y.addListener(myFunction) // Attach listener function on state changes

      
  return (
            <div className="instructors-cards-parent container my-5">
                            <div className="row justify-content-center text-center align-items-center">
                            <Swiper
                                slidesPerView={widget}
                                spaceBetween={30}
                                pagination={{
                                clickable: true,
                                }}
                                modules={[Pagination]}
                                className="mySwiper d-flex justify-content-center align-items-center"
                            >
                                {props.instructors.map((instructor) => (
                                    <SwiperSlide>
                                    <div class="card-instructors justify-content-center align-items-center"  style={{width:"18rem",minHeight:"250px"}} >
                                <img src={instructor.image}  style={{height: "270px",maxHeight:"270px",width:"100%",objectFit:"unset"}}   class="card-img-top img-thumbnail" alt="Sunset Over the Sea"/>
                                <h5 className="card-title">{instructor.name}</h5>
                                <button onClick={()=>moreInfo(instructor)} className="btn btn-dark btn-block my-2  ">Contacts</button>
                        </div>
                        </SwiperSlide>
                                    
                                ))}


                        </Swiper>




                            </div>
                        </div>

    
  )
}
