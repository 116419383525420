import React from 'react'
import Image from "./5124556.jpg"
import AnimatedPage from '../AnimatedPage/AnimatedPage';
import emailjs from '@emailjs/browser';
import { useRef ,useState} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import "./contact.css"
export default function ContactUs() {


  let [loadingButton,setLoadingButton]=useState(false)

  let username=useRef()
  let email=useRef()
  let message=useRef()


  
  const [values, setValues] = useState({
    email: '',
    message: ''
  });
  
  const handleChange = (e) => {
    setValues(values => ({
      ...values,
      [e.target.name]: e.target.value
    }))
  }
  
 async function sendEmail(e) {

    
    e.preventDefault()
    setLoadingButton(true)

      
    await emailjs.send('service_h6e4s57', 'template_edsuwms',{
      from_name: "dfds",
      message:message.current.value  
    }, 'OUZm6P09qtQ4u8urE')
    .then(response => {
      console.log('SUCCESS!', response);
       setValues({
        email: '',
        message: ''
      });
      email.current.value=""
      message.current.value=""
      username.current.value=""

      setLoadingButton(false)
      toast.success('Your Message Sent Successfully ')

    }, error => {
      console.log('FAILED...', error);
      setLoadingButton(false)
      setValues({
        email: '',
        message: ''
      });
      email.current.value=""
      message.current.value=""
      username.current.value=""
      toast.warning("OOOOPS !!! ,Your Message doesn't sent ")

    });

    
  }

  return (

    
    <>
    <ToastContainer/>
<AnimatedPage >

    <div className='contact-parent' id="contact_us" >
  <section className="vh-80">
    <div className="container-fluid h-custom">
      <div className="row d-flex justify-content-center align-items-center h-100">
        <div className="col-md-9 col-lg-6 col-xl-5">
          <img src={Image} className="img-fluid" alt="Sample image" data-aos="flip-down" />
        </div>
        <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1 " >
          <form className='my-5' onSubmit={sendEmail}>


           {/* Username input */}

          <div className="form-outline mb-4">
          <input type="text" id="loginName" ref={username} className="form-control border-bottom" />
          <label className="form-label " htmlFor="loginName"> username</label>
        </div>
      
            {/* Email input */}

        <div className="form-outline mb-4">
          <input type="email" id="loginName" className="form-control border-bottom"  ref={email} pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"  required  name='email' onChange={handleChange}/>
          <label className="form-label" htmlFor="loginName" >Email</label>
        </div>
      
       
            {/* Message input */}
            <div class="form-outline">
            <textarea class="form-control border-bottom" id="textAreaExample" rows="4"  ref={message}  name="message" onChange={handleChange} defaultValue={""}></textarea>
            <label className="form-label" htmlFor="loginName">Message</label>
            </div>

            <div className="text-center text-lg-start mt-4 pt-2">
            {loadingButton?        

              <button type='submit' disabled className="btn btn-primary  btn-lg mt-2 " style={{paddingLeft: '2.5rem', paddingRight: '2.7rem'}}>
                 
                                    Loading......
                </button>
                                
                :
                  <button type='submit'  className="btn btn-primary btn-lg mt-2 " style={{paddingLeft: '2.5rem', paddingRight: '2.5rem'}}> Send Message </button>
            }


            </div>

          </form>
        </div>
      </div>
    </div>

  </section>
</div>
</AnimatedPage>

    </>
 

  )
}
