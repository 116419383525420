import React, { useState } from 'react';

import AnimatedPage from '../AnimatedPage/AnimatedPage';
import Navbar_two from '../Navbar/Navbar2';


function AboutUs(props) {

    return (

            <div>
           <Navbar_two/>
           <AnimatedPage>
           <div className="page-section about-container container my-5 p-5">
                    <div className=" text-light about-content ">
                        <div className="row justify-content-center">
                            <div className="col-lg-8 wow fadeInUp">
                                <h1 className="text-center text-primary mb-3">Welcome to <span className='text-dark'>Professional Management</span>  Academy</h1>
                                <div className="text-lg text-dark">
                                    <p>At Professional Management Academy, we are dedicated to providing high-quality courses in project management, cost control, and various other disciplines. Our academy is designed to equip individuals with the skills and knowledge necessary to excel in their professional careers.
<br></br>
        Our courses in project management cover a wide range of essential topics, including project planning, execution, monitoring, and successful completion. Whether you're a beginner looking to enter the field or an experienced professional seeking to enhance your expertise, our comprehensive curriculum caters to learners of all levels.
        <br></br>


        At Professional Management Academy, we understand the importance of flexibility in learning. That's why we offer both in-person and online course options, allowing you to choose the format that best suits your schedule and preferences. Our online courses provide a convenient and interactive learning experience, ensuring you can access the material from anywhere at any time.
        <br></br>

        Whether you're looking to advance your career, enhance your skills, or explore new opportunities, Professional Management Academy is here to support your professional growth. Join us today and embark on a transformative educational journey that will empower you to succeed in the dynamic world of project management and cost control.
        <br></br>

        If you have any questions or would like to enroll in our courses, please don't hesitate to contact us. We look forward to welcoming you to Professional Management Academy and helping you achieve your goals!
        </p>
                                </div>
                            </div>


                            <div className="col-lg-10 mt-5">
      <h1 className="text-center mb-2 wow fadeInUp text-primary"><span className='text-dark'>Our</span> Location</h1>
      <div className='w-100 col-lg-12'>
{/*       
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d13779.174346380285!2d31.753739799999995!3d30.299937349999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2seg!4v1652915361761!5m2!1sen!2seg"
          width={"100%"}
          height={450}
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        /> */}
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d927758.0365995485!2d46.16302459548811!3d24.724997808389848!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f03890d489399%3A0xba974d1c98e79fd5!2sRiyadh%20Saudi%20Arabia!5e0!3m2!1sen!2seg!4v1698175680306!5m2!1sen!2seg"    
        width={"100%"}
          height={450}
          style={{ border: 0 }} allowFullScreen
           loading="lazy" referrerpolicy="no-referrer-when-downgrade">

           </iframe>

      </div>
    </div>


                        </div>
                    </div>
                </div>
           </AnimatedPage>
                
            </div>
          

);}

export default AboutUs;