import { faPenClip, faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { UserDataContext } from '../UserContext/UserContext';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
export default function Comments() {

  


  let {userDataObject,Base_url}=useContext(UserDataContext)
  const url = window.location.href;
  const parts = url.split('/');
  const lesson_id = parts[5];
  const course_id=parts[4]


  let comemntContent=useRef()
  let [comments,setComments]=useState([])

  let getLessonComments=async()=>{
    let data=await axios.get(`${Base_url}/courses/${course_id}/${lesson_id}/`)
    let comment=data.data.comments
    setComments(comment)
  }

  useEffect(()=>
  {
    getLessonComments()  

  },[comments])


  let AddComment = (e)=>{   // add comment to all comments list 
    e.preventDefault()
    let user=(userDataObject.user_id);
    let text=comemntContent.current.value
    if(text!=""){
      axios.post(`${Base_url}/comments/`,{
        user:user,
        text:text,
        lesson:lesson_id,
      })
      getLessonComments()
  
      comemntContent.current.value=""
      toast.success("Your Comment is Posted !"); 
       }
    else{
      toast.warning("Your Comment is Emtpy !")

    }
  }

  
  return (
    <div>
           <div>
      
      </div>
          <section>
        <div className="container w-100 my-5 py-5 comments">
          <div className="row d-flex justify-content-center">
            <div className="col-md-12 col-lg-12">
            <h4 className="mb-0">Recent comments</h4>
                    <p className="fw-light mb-4 pb-2">Latest Comments section by users</p>
                    <div className="card text-dark">
                    <ToastContainer />
   
                {
                  comments?.map((comment)=>
                    <div key={comment.id}>
                    
                    <div className="card-body p-4">
                    <div className="d-flex flex-start">
                      <FontAwesomeIcon className='px-3' icon={faUserCircle} size='3x'/>
                      <div>
                        <h6 className=" mb-1"> by <span className='fw-bold'> {comment.username}</span></h6>
                        <div className="d-flex align-items-center mb-3">
                          <p className="mb-0 data-time d-flex">
                          
                           <span className='mx-2' style={{"minWidth":"100%"}}>{new Date(comment.created_at).toLocaleString([], { hour: '2-digit', minute: '2-digit' })}</span>
                          
                           <p className='mx-5'>{new Date(comment.created_at).toLocaleDateString()}</p>

                          </p>
                       </div>
                        <p className="my-2">
                         {comment.text}
                        </p>
                      </div>
                    </div>
                  </div>
                  <hr className="my-0" />
                    </div>
                  )
                }

               
                
                <div className="card-footer py-3 border-0 my-2" style={{backgroundColor: '#F8F3Fa'}}>
                    <div className="d-flex flex-start w-100">
                    <FontAwesomeIcon className='px-3 user-add-comment' icon={faPenClip} size='3x'/>
                      <div className="form-outline w-100">
                          <textarea ref={comemntContent} className="form-control" id="textAreaExample" rows={4} style={{background: '#fff',resize:"none"}} defaultValue={""} />
                      </div>
                      </div>
                      <div className='w-100'></div>

                      <div className="d-flex  float-end mt-2 pt-1">
                      <button type="button" onClick={AddComment} className="btn btn-light btn-lg mx-2 text-black" >Post a Comment</button>
                      <button type="button" className="btn btn-dark btn-sm">Cancel</button>
                      </div>


                   
                </div>






              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
