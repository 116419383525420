import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import axios from "axios"
import { Link, useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import { UserDataContext } from '../../UserContext/UserContext'
import Joi, { date } from 'joi';

export default function LoginForm() {
  let {Base_url}=useContext(UserDataContext)

  const password_at_register = useRef(null);
  const repet_password_at_register = useRef(null);

  let [joiErrors,setJoiErrors]=useState([])  // error from joi
    
  let [loadingButton,setLoadingButton]=useState(false) // loading while send data to api

  let navigate=useNavigate();

  let {decodeToken,userDataObject}=useContext(UserDataContext)
  

  /* Register */
  let [userRegisterData,setUserRegisterData]=useState({
    username:"",
    email:"",
    password:"",
  })

  let changeRegisterData=(e)=>{
    setJoiErrors([])
    setUserRegisterData({...userRegisterData,[e.target.name]:e.target.value})
  }

  let submitRegister=async(e)=>{
    e.preventDefault();
    if (password_at_register.current.value === repet_password_at_register.current.value) {


      let validate=validationForm2();
      setLoadingButton(true)
      
      if (validate.error){
        setJoiErrors(validate.error.details);
        setLoadingButton(false)
  
      }
      else{
        try {
          let data=await axios.post(`${Base_url}/register/`,userRegisterData)
          setLoadingButton(false)
          localStorage.setItem("Token",data.data.access)
          
          decodeToken()
          navigate("/")
  
        } catch (error) {
          setJoiErrors([{message:"OOOPs , SomeThing Wrong ... Try again later"}])
  
            setLoadingButton(false)
          
        }
  
      }
  
    }
    else{
      setJoiErrors([{message:"Passwords not Matched"}])
    }
  }

  let validationForm2=(e)=>{
    let schema=Joi.object({
      username:Joi.string(),
        email:Joi.string().email({tlds:{allow:['com', 'net']}}).required(),
        password:Joi.string().uppercase().required().min(8).lowercase(),

    })
  
    return schema.validate(userRegisterData,{abortEarly:false})
  }



  /* Login */

  let [userLoginData,setUserLoginData]=useState({
    email:"",
    password:""
  })

  let changeLoginData=(e)=>{
    setJoiErrors([])
    setUserLoginData({...userLoginData,[e.target.name]:e.target.value})
  }

  // let submitLogin=async(e)=>{
    
  //     e.preventDefault();
  //     let validate=validationForm();
  //     setLoadingButton(true)
      
  //   if (validate.error){
  //       setJoiErrors(validate.error.details);
  //       setLoadingButton(false)
  //   }

  //   else{
  //     try {
  //       let data=await axios.post("http://127.0.0.1:8000/api/login/",userLoginData)
  //           setLoadingButton(false)
  //           localStorage.setItem("Token",data.data.access)
  //            decodeToken()
  //            localStorage.setItem("User_id",userDataObject.user_id)

  //           navigate("/")


  //     } catch (error) {
  //         setJoiErrors([{message:"Wrong Cradnital"}])
  //         setLoadingButton(false)

  //     }


  //   }

  // }

  let submitLogin = async (e) => {
    e.preventDefault();
    let validate = validationForm();
    setLoadingButton(true);
  
    if (validate.error) {
      setJoiErrors(validate.error.details);
      setLoadingButton(false);
    } else {
      try {
        let data = await axios.post(`${Base_url}/login/`, userLoginData);
        setLoadingButton(false);
        localStorage.setItem("Token", data.data.access);
        decodeToken();
        navigate("/");
      } catch (error) {
        setJoiErrors([{ message: "Wrong Cradnital" }]);
        setLoadingButton(false);
      }
    }
  };
  
  // let submitLogin = async (e) => {
  //   e.preventDefault();
  //   let validate = validationForm();
  //   setLoadingButton(true);
  
  //   if (validate.error) {
  //     setJoiErrors(validate.error.details);
  //     setLoadingButton(false);
  //   } else {
  //     try {
  //       let data = await axios({
  //         headers: {
  //           'Access-Control-Allow-Origin': '*',
  //           'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
  //         },
  //         method: 'post',
  //         url: `${Base_url}/login/`,
  //         data: userLoginData,

  //       });
  //       setLoadingButton(false);
  //       localStorage.setItem("Token", data.data.access);
  //       decodeToken();
  //       navigate("/");
  //     } catch (error) {
  //       setJoiErrors([{ message: "Wrong Credential" }]);
  //       setLoadingButton(false);
  //     }
  //   }
  // };
  
  let validationForm=(e)=>{
    let schema=Joi.object({
      email:Joi.string().email({tlds:{allow:['com', 'net']}}).required(),
      password:Joi.required()
    })
  
    return schema.validate(userLoginData,{abortEarly:false})
  }

  return (
 <div >

  {/* Pills navs */}
  <div className='d-flex justify-content-center'>
  <ul className="nav nav-pills nav-justified mb-3" style={{"maxWidth":"600px"}} id="ex1" role="tablist">
    <li className="nav-item" role="presentation" onClick={()=>setJoiErrors([])}>
      <a className="nav-link active w-100 mx-1" id="tab-login"  data-mdb-toggle="pill" href="#pills-login" style={{"width":"150px"}} role="tab" aria-controls="pills-login" aria-selected="true">Login</a>
    </li>
    <li className="nav-item" role="presentation" onClick={()=>setJoiErrors([])}>
      <a className="nav-link w-100" id="tab-register" data-mdb-toggle="pill" href="#pills-register" style={{"width":"150px"}} role="tab" aria-controls="pills-register" aria-selected="false">Register</a>
    </li>
  </ul>
  </div>

  {/* Pills navs */}
  {/* Pills content */}
  <div className="tab-content container"style={{"maxWidth":"600px"}}> 
    <div className="tab-pane fade show active" id="pills-login" role="tabpanel" aria-labelledby="tab-login" >
    {joiErrors.slice(0, 2).map((err)=><h6 className='alert' key={Math.random()}> {err.message}</h6>)}

      <form className='my-5'>
     
        {/* Email input */}
        <div className="form-outline mb-4">
          <input type="text" id="loginName" name='email' className="form-control" onChange={changeLoginData} />
          <label className="form-label" htmlFor="loginName">email</label>
        </div>
        {/* Password input */}
        <div className="form-outline mb-4">
          <input type="password" id="loginPassword" name='password' className="form-control" onChange={changeLoginData} />
          <label className="form-label" htmlFor="loginPassword">Password</label>
        </div>

        {/* Submit button */}
        {loadingButton?        <button type="submit" className="btn btn-dark x text-light btn-block mb-4" disabled>wait ..</button>
:
<>
<button type="submit" className="btn btn-block text-light btn-dark" onClick={submitLogin}>Login</button>

<button type="submit" className="btn btn-secondary btn-block  text-white" onClick={()=>navigate("/password/reset/send/")}>Forget Password</button>
{/* <Link to={"/password/reset/send/"} className="btn btn-secondary btn-block mb-3  text-white">Forget Password</Link> */}

</>

}

      </form>
    </div>

    <div className="tab-pane fade" id="pills-register" role="tabpanel" aria-labelledby="tab-register">
    {joiErrors.slice(0, 1).map((err)=><h6 className='alert' key={Math.random()}> {err.message}</h6>)}

      <form className='my-3'>

        {/* Username input */}
        <div className="form-outline mb-4">
          <input type="text" id="registerUsername" className="form-control"  name="username" onChange={changeRegisterData}/>
          <label className="form-label" htmlFor="registerUsername">Username</label>
        </div>
        {/* Email input */}
        <div className="form-outline mb-4">
          <input type="email" id="registerEmail" className="form-control" name="email" onChange={changeRegisterData} />
          <label className="form-label" htmlFor="registerEmail">Email</label>
        </div>
        {/* Password input */}
        <div className="form-outline mb-4">
          <input type="password" id="registerPassword" ref={password_at_register} className="form-control" name="password" onChange={changeRegisterData}/>
          <label className="form-label" htmlFor="registerPassword">Password</label>
        </div>
        {/* Repeat Password input */}
        <div className="form-outline mb-4">
          <input type="password" id="registerRepeatPassword" ref={repet_password_at_register} className="form-control" />
          <label className="form-label" htmlFor="registerRepeatPassword">Repeat password</label>
        </div>



        {/* Submit button */}
        {loadingButton?  
              <button type="submit" className="btn btn-dark btn-block mb-3 mx-2 x text-white" disabled onClick={submitRegister}>wait ...</button>
      : 
      <>
             <button type="submit" className="btn btn-dark btn-block mb-3 mx-2 x text-white" onClick={submitRegister}>Sign in</button>
      
      </>
}
      </form>
    </div>
  
  </div>
  {/* Pills content */}
</div>

  )
}
