import React from 'react'

export default function Footer() {
    return (
        <footer className="bg-dark text-center text-white">
            {/* Grid container */}
            <div className="container p-4 pb-0">
                {/* Section: Social media */}
                <section className="mb-4">
                    {/* Facebook */}
                    <a className="btn btn-outline-light btn-floating m-1 bg-primary" href="https://www.facebook.com/professionalmanagementacademy" target="_blank" role="button"><i style={{fontSize:"15px"}} className="fab fa-facebook-f" /></a>
                    {/* Youtube */}
                    <a className="btn btn-outline-light btn-floating m-1 bg-danger " href="https://youtube.com/@professionalmanagementacademy" target="_blank" role="button"><i style={{fontSize:"15px"}} className="fab fa-youtube" /></a>
                    {/* Linkedin */}
                    <a className="btn btn-outline-light btn-floating m-1 bg-primary" href="https://www.linkedin.com/company/professionalmanagementacademy"target="_blank" role="button"><i style={{fontSize:"15px"}} className="fab fa-linkedin-in" /></a>
                   
                    <a className="btn btn-outline-light btn-floating m-1 bg-success " href="https://wa.me/message/JRMU5JCMEHZRM1" target="_blank" role="button"><i style={{fontSize:"15px"}} className="fab fa-whatsapp"  /></a>

                </section>
                {/* Section: Social media */}
            </div>
            {/* Grid container */}
            {/* Copyright */}
            <div className="text-center p-3" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
                © 2023 Copyright : 
                <a className="text-white fw-bold" href="/"> Professional Management Academy</a>
            </div>
            {/* Copyright */}
        </footer>

    )
}
