import React from 'react'

import { UserDataContext} from "../../UserContext/UserContext"

import { toast } from "react-toastify";
import axios from 'axios';
import { useContext } from 'react';
import { useState } from 'react';

export default function ForgetPasswordForm() {
  let {Base_url}=useContext(UserDataContext)
  let [loading,setLoading]=useState(false)

  const ResetURL = Base_url + "/auth/password/reset/send/"


  const onFormSubmit = e => {
    e.preventDefault();

    if (e.target.dataSubmitted === true) return;

    e.target.dataSubmitted = true;

    let email = document.getElementById('loginName').value;

    setLoading(true); // Set loading to true before making the API call

    axios.post(ResetURL, {
      email: email
    })
      .then(res => {
        e.target.dataSubmitted = false;
        toast.success("Password link sent to your mail.");
      })
      .catch(err => {
        e.target.dataSubmitted = false;
        console.log(err);
        if (err.response?.data?.email) {
          toast.error("Email does not exist");
        } else {
          toast.error("Something wrong occurred, please try again later");
        }
      })
      .finally(() => {
        setLoading(false); // Set loading back to false after the API call is completed
      });
  };
  return (
    <>

  {/* Section: Design Block */}
  <section className="background-radial-gradient login-page overflow-hidden" style={{height:"100vh"}}>
    <style dangerouslySetInnerHTML={{__html: "\n    .background-radial-gradient {\n      background-color: hsl(218, 41%, 15%);\n      background-image: radial-gradient(650px circle at 0% 0%,\n          hsl(218, 41%, 35%) 15%,\n          hsl(218, 41%, 30%) 35%,\n          hsl(218, 41%, 20%) 75%,\n          hsl(218, 41%, 19%) 80%,\n          transparent 100%),\n        radial-gradient(1250px circle at 100% 100%,\n          hsl(218, 41%, 45%) 15%,\n          hsl(218, 41%, 30%) 35%,\n          hsl(218, 41%, 20%) 75%,\n          hsl(218, 41%, 19%) 80%,\n          transparent 100%);\n    }\n\n    #radius-shape-1 {\n      height: 220px;\n      width: 220px;\n      top: -60px;\n      left: -130px;\n      background: radial-gradient(#44006b, #ad1fff);\n      overflow: hidden;\n    }\n\n    #radius-shape-2 {\n      border-radius: 38% 62% 63% 37% / 70% 33% 67% 30%;\n      bottom: -60px;\n      right: -110px;\n      width: 300px;\n      height: 300px;\n      background: radial-gradient(#44006b, #ad1fff);\n      overflow: hidden;\n    }\n\n    .bg-glass {\n      background-color: hsla(0, 0%, 100%, 0.9) !important;\n      backdrop-filter: saturate(200%) blur(25px);\n    }\n  " }} />
    <div className="container px-4 py-5 px-md-5 text-center text-lg-start my-5">
      <div className="row gx-lg-5 align-items-center mb-5">
        <div className="col-lg-6 mb-5 mb-lg-0" style={{zIndex: 10}}>
          <h1 className="my-5 display-5 fw-bold ls-tight" style={{color: 'hsl(218, 81%, 95%)'}}>
            The Best Academy <br />
            <span style={{color: 'hsl(218, 81%, 75%)'}}>for your business</span>
          </h1>
          <p className="mb-4 opacity-70" style={{color: 'hsl(218, 81%, 85%)'}}>
          Learn smartly.
          Don't chase a lot of platforms. 
          Now, you will find all you need to enhance your professionalism here.
          Just, start and fly.
          </p>
        </div>
        <div className="col-lg-6 mb-5 mb-lg-0 position-relative my-3">
          <div id="radius-shape-1" className="position-absolute rounded-circle shadow-5-strong" />
          <div id="radius-shape-2" className="position-absolute shadow-5-strong" />
          <div className="card bg-glass">
            <div className="card-body px-4 py-5 px-md-5">
           



            <p>Enter your email, which you are logging in with, and check the inbox. You will get the link to reset the password.</p>


            <form dataSubmitted={true} onSubmit={onFormSubmit} className='my-5'>
              {/* Email input */}
              <div className="form-outline mb-4">
                <input required type="text" id="loginName" name='email' className="form-control" />
                <label className="form-label" htmlFor="loginName">Enter Your Email</label>
              </div>
              {loading?
                            <button className='btn btn-dark btn-block' disabled>wait ..</button>          
:
<button className='btn btn-dark btn-block'>Send Reset Link</button>          
}

          </form>




           
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* Section: Design Block */}

       <div>

</div>
</>

  )
}
