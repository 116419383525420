import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from "react-toastify";
import axios from 'axios';
// import {BASE_URL} from "./utils"
import { UserDataContext} from "../../UserContext/UserContext"
import { useContext } from 'react';

export default function NewPasswordForm() {
  let {Base_url}=useContext(UserDataContext)

  let navigate = useNavigate()

  let params = useParams()
  const ResetURL = Base_url + "/auth/password/reset/confirm/"

  let otp = params.otp

  const onFormSubmit = e => {
    e.preventDefault();
    if (e.target.dataSubmitted === true) return


    let password_1 = document.getElementById('resetPassword').value;
    let password_2 = document.getElementById('resetPasswordRepeted').value;
    
    if (password_1 !== password_2){

      toast.error("Passwords do not match")
      return
    }

    e.target.dataSubmitted = true

    axios.post(ResetURL, {
      new_password : password_1,
      otp : otp
    }).then(
      res => {
        e.target.dataSubmitted = false
          navigate('/login')
      }
    ).catch(err => {
      e.target.dataSubmitted = false
      console.log(err)
      toast.error("Something wrong occured, please try again later")
    })
  }

  return (
    <>

  {/* Section: Design Block */}
  <section className="background-radial-gradient login-page overflow-hidden" style={{height:"100vh"}}>
    <style dangerouslySetInnerHTML={{__html: "\n    .background-radial-gradient {\n      background-color: hsl(218, 41%, 15%);\n      background-image: radial-gradient(650px circle at 0% 0%,\n          hsl(218, 41%, 35%) 15%,\n          hsl(218, 41%, 30%) 35%,\n          hsl(218, 41%, 20%) 75%,\n          hsl(218, 41%, 19%) 80%,\n          transparent 100%),\n        radial-gradient(1250px circle at 100% 100%,\n          hsl(218, 41%, 45%) 15%,\n          hsl(218, 41%, 30%) 35%,\n          hsl(218, 41%, 20%) 75%,\n          hsl(218, 41%, 19%) 80%,\n          transparent 100%);\n    }\n\n    #radius-shape-1 {\n      height: 220px;\n      width: 220px;\n      top: -60px;\n      left: -130px;\n      background: radial-gradient(#44006b, #ad1fff);\n      overflow: hidden;\n    }\n\n    #radius-shape-2 {\n      border-radius: 38% 62% 63% 37% / 70% 33% 67% 30%;\n      bottom: -60px;\n      right: -110px;\n      width: 300px;\n      height: 300px;\n      background: radial-gradient(#44006b, #ad1fff);\n      overflow: hidden;\n    }\n\n    .bg-glass {\n      background-color: hsla(0, 0%, 100%, 0.9) !important;\n      backdrop-filter: saturate(200%) blur(25px);\n    }\n  " }} />
    <div className="container px-4 py-5 px-md-5 text-center text-lg-start my-5">
      <div className="row gx-lg-5 align-items-center mb-5">
        <div className="col-lg-6 mb-5 mb-lg-0" style={{zIndex: 10}}>
          <h1 className="my-5 display-5 fw-bold ls-tight" style={{color: 'hsl(218, 81%, 95%)'}}>
            The Best Academy <br />
            <span style={{color: 'hsl(218, 81%, 75%)'}}>for your business</span>
          </h1>
          <p className="mb-4 opacity-70" style={{color: 'hsl(218, 81%, 85%)'}}>
          Learn smartly.
          Don't chase a lot of platforms. 
          Now, you will find all you need to enhance your professionalism here.
          Just, start and fly.
          </p>
        </div>
        <div className="col-lg-6 mb-5 mb-lg-0 position-relative my-3">
          <div id="radius-shape-1" className="position-absolute rounded-circle shadow-5-strong" />
          <div id="radius-shape-2" className="position-absolute shadow-5-strong" />
          <div className="card bg-glass">
            <div className="card-body px-4 py-5 px-md-5">

            <p>Enter your New Password</p>

            <form onSubmit={onFormSubmit} className='my-5'>
                    <div className="form-outline mb-4">
                      <input type="text" id="resetPassword" className="form-control" name="new_password" />

                      <label className="form-label" htmlFor="resetPassword">Password</label>
                    </div>


                    <div className="form-outline mb-4">
                      <input type="text" id="resetPasswordRepeted" className="form-control" />
                      <label className="form-label" htmlFor="resetPasswordRepeted">Repeat password</label>
                    </div>
            <button className='btn btn-dark btn-block'>Save</button>
         </form>




           
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* Section: Design Block */}

       <div>

</div>
</>

  )
}
