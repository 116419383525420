import React, { useContext, useEffect, useState } from 'react'
import Navbar from '../Navbar/Navbar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faHourglassHalf} from '@fortawesome/free-regular-svg-icons'
import AnimatedPage from '../AnimatedPage/AnimatedPage'
import axios from 'axios'
import ExamTemplate from './ExamTemplate'
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom'
import Navbar_two from '../Navbar/Navbar2'
import { UserDataContext } from '../UserContext/UserContext'


export default function Exam() {
  
  let {Base_url}=useContext(UserDataContext)

  let [loading,setLoading]=useState(true)
  
  let [topics,setTopics]=useState([]) // All Topics State 
  let [exams,setExams]=useState([]) // All Exams State

  const url = window.location.href;
  const parts = url.split('/');
  const courseName = parts[parts.length - 1];


  let token = localStorage.getItem('Token');
  const isLogged = !(token === null || token === 'undefined' || token === '');

  useEffect(() => {
    
    async function getTopics() {
      try {
        const response = await axios.get(`${Base_url}/${courseName}/topics`);
        const data = response.data;
        setTopics(data);
      } catch (error) {
        console.error(error);
      }
    }
  
    async function getExams() {
      try {
        const response = await axios.get(`${Base_url}/${courseName}/exams`);
        const data = response.data;
        setExams(data);
      } catch (error) {
        console.error(error);
      }
    }
  
    if(courseName=="PMP"){
      async function loadData() {
        await Promise.all([getTopics(), getExams()]);
        setLoading(false);
      }
      loadData()
    
    }
    else{
      console.log("nioooooo");
      async function loadData() {
        await getExams()
        setLoading(false);
      }
      loadData()
    }

  
    // loadData();
  }, []);



  let navigate=useNavigate()



  return (
    <>
    
    <Navbar_two/>
<AnimatedPage>
<div className='container exam-container '>

<div className="row">
  {isLogged?<ExamTemplate/>
:navigate("/login")}


<div className="topics-parent col-12 col-lg-4 ">
    <div className="accordion p-2 accordion-borderless" id="accordionFlushExampleX">



      <div className="accordion-item">
        <h2 className="accordion-header" id="flush-headingTwoX">
          <button className="accordion-button collapsed" type="button" data-mdb-toggle="collapse"
            data-mdb-target="#flush-collapseTwoX" aria-expanded="false" aria-controls="flush-collapseTwoX">
            Topics
          </button>
        </h2>
        <div id="flush-collapseTwoX" className="accordion-collapse collapse" aria-labelledby="flush-headingTwoX"
          data-mdb-parent="#accordionFlushExampleX">

      {loading?
            <div className="accordion-body placeholder-glow">
            <ul>
                <li className='placeholder w-100 bg-dark'>   </li>
                <li className='placeholder w-100 bg-dark'>   </li>
                <li className='placeholder w-100 bg-dark'>   </li>
                <li className='placeholder w-100 bg-dark'>   </li>
                <li className='placeholder w-100 bg-dark'>   </li>

              </ul> 
        
            </div> :

          <div className="accordion-body" style={{maxHeight:"200px",minWidth:"200px",overflowX:"hidden",fontSize:".9rem",overflowY:"scroll"}}>
          <ul>
            {topics.map((topic) => (
              <li className='topic'>
                <FontAwesomeIcon icon={faCheckCircle} className='text-success mx-2' />
                
                <NavLink to={`/Exam/T${topic.id}`}>{topic.name}</NavLink>
              </li>
            ))}
             
            </ul> 

          </div>
}

      

      
      
      
        </div>
      </div>


      <div className="accordion-item">
        <h2 className="accordion-header" id="flush-headingThreeX">
          <button className="accordion-button collapsed" type="button" data-mdb-toggle="collapse"
            data-mdb-target="#flush-collapseThreeX" aria-expanded="false" aria-controls="flush-collapseThreeX">
            Exams
          </button>
        </h2>
        <div id="flush-collapseThreeX" className="accordion-collapse collapse" aria-labelledby="flush-headingThreeX"
          data-mdb-parent="#accordionFlushExampleX">
{loading?
      <div className="accordion-body placeholder-glow">
      <ul>
          <li className='placeholder w-100 bg-dark'>   </li>
          <li className='placeholder w-100 bg-dark'>   </li>
          <li className='placeholder w-100 bg-dark'>   </li>
          <li className='placeholder w-100 bg-dark'>   </li>
          <li className='placeholder w-100 bg-dark'>   </li>
        </ul> 
  
    </div>
:
      <div className="accordion-body"  style={{maxHeight:"200px",minWidth:"200px",overflowX:"hidden",fontSize:".9rem",overflowY:"scroll"}}>
      <ul>
            
      {exams.map((exam) => (
          <li className='exam'>
            <FontAwesomeIcon icon={faCheckCircle} className='text-success mx-2' />
            <Link to={`/Exam/E${exam.id}`} >{exam.name}</Link>
          </li>
        ))}
      </ul> 

      </div>
}


     


        </div>
      </div>




    </div>
</div>


</div>

</div>

</AnimatedPage>

  </>
    )
}
