import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

document.addEventListener('keydown', function (event) {
    if (event.keyCode === 123) {
      event.preventDefault();
      toast.warning("OOOPS , The Page inspect is Blocked");
    }
  });

document.addEventListener('contextmenu', function (event) {
    event.preventDefault();
  });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
    <ToastContainer/>
        <App />
    
    </BrowserRouter>

);

