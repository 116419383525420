import React from 'react'
import image from "./1847.jpg"
import { Link } from 'react-router-dom'
import AnimatedPage from '../AnimatedPage/AnimatedPage'

export default function NotFound() {
  return (
    <AnimatedPage>
   <div className='pagenotfound-container'>
        <Link to={"/"}>

        <button className='btn btn-dark btn-lg'>Back To Home</button>

        </Link>
    </div>
    </AnimatedPage>
 
  )
}
