import Swal from 'sweetalert2';

export  let moreInfo = ({ name, review, mobile_number, LinkdIn }) => {
    Swal.fire({
        title: name,
        text: review,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'LinkedIn',
        cancelButtonText: 'Mobile Number',
        customClass: {
            confirmButton: 'btn btn-secondary',
            cancelButton: 'btn btn-dark'
        }
    }).then((result) => {
        if (result.isConfirmed) {
            window.open(LinkdIn, '_blank');
        } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire({
                title: mobile_number,
                confirmButtonText: 'Call',
            }).then((result) => {
                if (result.isConfirmed) {
                    window.open(`tel:${mobile_number}`);
                }
            });
        }
    });
};