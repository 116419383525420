import { faStop } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';

export const MakingAnswerTable = ({questions,type}) => {
  

  const results = [];
  for (let i = 0; i < questions.questions.length; i++) {
    const question_type = questions.questions[i].question_type;
    const question = questions.questions[i];
    
    const correctAnswerTexts = Array.from(
      question.answers
        .filter(answer => answer.is_correct)
        .map(answer => answer.text)
    );

    
  
    // const user_answer = sessionStorage.getItem(question.text)?.split(',') || [];

    const user_answer = JSON.parse(sessionStorage.getItem(question.text)) || [];

    // // let is_correct = user_answer.length > 0 && user_answer.every(e => correctAnswerTexts.includes(e));
    // let is_correct = user_answer.length > 0 && arraysEqual(user_answer, correctAnswerTexts);

    // function arraysEqual(a, b) {
    //   if (a.length !== b.length) {
    //     return false;
    //   }
      
    //   const stringA = a.join(',');
    //   const stringB = b.join(',');
    
    //   return stringA === stringB;
    // }

// let user_answer_arr = [user_answer]; // Convert user_answer to an array

// let is_correct = user_answer_arr.length > 0 && arraysEqual(user_answer_arr, correctAnswerTexts);

// function arraysEqual(a, b) {
//   if (a.length !== b.length) {
//     return false;
//   }
  
//   const setA = new Set(a);
//   const setB = new Set(b);
  
//   for (const item of setA) {
//     if (!setB.has(item)) {
//       return false;
//     }
//   }
  
//   return true;
// }
const user_answer_arr = Array.isArray(user_answer) ? user_answer : [user_answer];

let is_correct;
if (question_type === "MC") {
  const user_answer_set = new Set(user_answer_arr);
  const correctAnswerTexts_set = new Set(correctAnswerTexts);
  is_correct = user_answer_set.size === correctAnswerTexts_set.size &&
    Array.from(user_answer_set).every((item) => correctAnswerTexts_set.has(item));
} else {
  is_correct = user_answer === correctAnswerTexts[0];
}

    results.push({
      question: question.text,
      question_type: question_type,
      user_answer: user_answer,
      is_correct: is_correct?"Correct" :"not Correct" ,
      correctAnswer: correctAnswerTexts,
      explanation: question.explanation,
    });
  }
  
  sessionStorage.setItem("results", JSON.stringify(results));
  const navigate = useNavigate();
  const handleResultClick = () => {

    Swal.fire({
      title: "Are You Sure for Submiting !!!",
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      showCancelButton:true
  }).then((result) => {
      if (result.isConfirmed) {
        navigate("/SeeTheAnswerTable");
      }
  });
   
  };

  return (
    <>
      {type=="button"?<button type="button" onClick={handleResultClick} className="btn btn-primary">
        Result
       </button>:<FontAwesomeIcon onClick={handleResultClick} icon={faStop} size='2x' />
       }
  </>
    );
};