import React from 'react'

export default function SeeTheExpalition(props) {
    return (
        <div>
            <div>


                <div className="modal fade" id="exampleModal2" tabIndex="{-1}" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title text-dark" id="exampleModalLabel">Explanation</h5>
                                <button type="button" className="btn-close" data-mdb-dismiss="modal" aria-label="Close">
                                </button>
                            </div>
                            <div className="modal-body text-dark">{props.explanation}</div>
                        </div>
                    </div>
                </div>
            </div>

            
        </div>
    )
}
