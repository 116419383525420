import React from 'react'
import Navbar from '../Navbar/Navbar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import "./home.css"
import { Link } from 'react-router-dom'
import Courses from '../Courses/Courses'
import ContactUs from '../ContactUs/ContactUs'
import Instructors from '../Instructors/Instructors'
import Footer from '../Footer/Footer'


export default function Home() {
  return (
    <>
    <Navbar/>

<div className='home_container' >


</div >

<Courses/>

    <Instructors/>
    <ContactUs/>


    <Footer/>

    </>

  )}
