import React from 'react'
import { Link } from 'react-router-dom'
import { useContext,useState,useEffect } from 'react';
import { UserDataContext } from '../UserContext/UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserLarge } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

import IMAGE from "./icon.ico"

export default function Navbar_two(props) {

  const isHomeComponent = props.isHome;

  
  let {Base_url}=useContext(UserDataContext)

  const navbarStyle = {
    position: isHomeComponent ? "absoulte" : 'relative'
  }

  let {userDataObject,logOut}=useContext(UserDataContext)

  let [showname,setShowName]=useState()

   let datafun=async()=>{
    let {user_id}= await userDataObject
    let {data}= await axios.get(`${Base_url}/users/${user_id}/`)
    setShowName(data)
    }


  useEffect(()=>{
    datafun()
  },[userDataObject])



  return (
  <div style={{zIndex:"2",maxWidth:"100vw" ,position:`realtive`,width:"100%"}} >
  {/* Navbar */}
  <nav className="navbar navbar-expand-lg navbar-light" style={{}} >
        {/* Container wrapper */}
    <div className="container-fluid container">
      {/* Toggle button */}
      <button className="navbar-toggler text-primary" type="button" data-mdb-toggle="collapse" data-mdb-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <i className="fas fa-bars" />
      </button>
      {/* Collapsible wrapper */}
      <div className="collapse navbar-collapse  p-2" id="navbarSupportedContent">
        {/* Navbar brand */}
        <Link className="nav-link" to={"/"} >
        <img src={IMAGE} height={35} width={45} alt="MDB Logo" loading="lazy" />

        </Link>



        {showname&&
                <ul className="navbar-nav me-auto mb-2 mb-lg-0" >
                <li className="nav-item">
                  <Link className="nav-link fw-bold" to={"/"}  style={{...navbarStyle}}>Courses</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fw-bold" to={"/"}  style={{...navbarStyle}}>Instructors</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fw-bold" to={"/"}  style={{...navbarStyle}}>ContactUs</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fw-bold" to={"/AboutUs"}  style={{...navbarStyle}}>AboutUs</Link>
                </li>
              </ul>
      }



      </div>



      {
        showname?<>
        <FontAwesomeIcon icon={faUserLarge}/>
        <span className='mx-1 my-1 fw-bold' style={{...navbarStyle}}>{showname?showname.username||showname.email:""}</span>
        <Link className="nav-link" to={"/login"} >

        <button className='btn btn-dark mx-2' onClick={logOut}>LogOut</button>
        </Link>     
        </>
          :
          <Link className="nav-link" to={"/Login"}>

          <button className='btn btn-primary mx-2'>Login</button>
          </Link>

      }




    </div>
    {/* Container wrapper */}
  </nav>
  {/* Navbar */}
</div>

  )
}
