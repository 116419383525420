import React from 'react'
import { Link } from 'react-router-dom'
import { useContext,useState,useEffect } from 'react';
import { UserDataContext } from '../UserContext/UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserLarge } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import IMAGE from "./icon.ico"

export default function Navbar(props) {

  let {Base_url}=useContext(UserDataContext)
  const handleClickScroll = (e) => {
    const element = document.getElementById(e);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };


  
  const isHomeComponent = props.isHome;



  let {userDataObject,logOut}=useContext(UserDataContext)

  let [showname,setShowName]=useState()

   let datafun=async()=>{
    let {user_id}= await userDataObject
    let {data}= await axios.get(`${Base_url}/users/${user_id}/`)
    setShowName(data)
    }


  useEffect(()=>{
    datafun()
  },[userDataObject])



  return (
  <div style={{zIndex:"2",maxWidth:"100vw" ,position:`absolute`,width:"100%"}} >
  {/* Navbar */}
  <nav className="navbar navbar-expand-lg navbar-light" style={{}} >
        {/* Container wrapper */}
    <div className="container-fluid container">
      {/* Toggle button */}
      <button className="navbar-toggler text-primary" type="button" data-mdb-toggle="collapse" data-mdb-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <i className="fas fa-bars" />
      </button>
      {/* Collapsible wrapper */}
      <div className="collapse navbar-collapse  p-2" id="navbarSupportedContent">
        {/* Navbar brand */}
        <Link className="nav-link" to={"/"} >
        <img src={IMAGE} height={35} width={45} alt="MDB Logo" loading="lazy" />

        </Link>



        {showname&&
                <ul className="navbar-nav me-auto mb-2 mb-lg-0" >
                <li className="nav-item">
                  <Link className="nav-link fw-bold"  onClick={()=>handleClickScroll("courses")}  >Courses</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fw-bold" onClick={()=>handleClickScroll("instructor")}   >Instructors</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fw-bold" onClick={()=>handleClickScroll("contact_us")}   >ContactUs</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link fw-bold" to={"/AboutUs"}  >AboutUs</Link>
                </li>
              </ul>
      }



      </div>



      {
        showname?<>
        <FontAwesomeIcon icon={faUserLarge} color='white' alt="ssss "/>
        <span className='mx-1 my-1 h6 text-white' >{showname?showname.username||showname.email:""}</span>
        <Link className="nav-link " to={"/login"} >

        <button className='btn btn-dark mx-2' onClick={logOut}>LogOut</button>
        </Link>     
        </>
          :
          <Link className="nav-link" to={"/Login"}>

          <button className='btn btn-primary mx-2'>Login</button>
          </Link>

      }




    </div>
    {/* Container wrapper */}
  </nav>
  {/* Navbar */}
</div>

  )
}
