import React, { useContext, useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHourglassHalf } from '@fortawesome/free-regular-svg-icons'
import { faArrowLeft, faArrowRight, faCheck, faLanguage, faList } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import SeeAllQuestion from './Modals/SeeAllQuestion'
import SeeTheExpalition from './Modals/SeeTheExpalition'
import SeeTheTransltion from './Modals/SeeTheTransltion'
import { MakingAnswerTable } from './MakingAnswerTable'
import { ToastContainer, toast } from 'react-toastify';
import "./exam.css"
import { UserDataContext } from '../UserContext/UserContext'
import Swal from 'sweetalert2'
import { warning } from 'framer-motion'

export default function ExamTemplate() {

  let {Base_url}=useContext(UserDataContext)

  const navigate = useNavigate();
  const { id } = useParams(); // destructuring Url object

  let [currentQuestion, setCurrentQuestion] = useState(0)

  const [questions, setQuestions] = useState([]); // the questions for the current Exam or Topic

  let [Questionloading, setQuestionLoading] = useState(true)
  let [IntroducPage, setIntroducPage] = useState(true)
  let [userAnswers, setUserAnswers] = useState({});
  const [showAnswer, setShowAnswer] = useState(false);

  const [timeRemaining, setTimeRemaining] = useState(); // in seconds

  const [Continue, setContinue] = useState(false)
  let [progress, setProgress] = useState(0)

  useEffect(() => {
    sessionStorage.clear();
    const interval = setInterval(() => {
      setTimeRemaining(prevTime => prevTime - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);



  useEffect(() => {
    if (timeRemaining === 0) {
      Swal.fire(
        'OOOPS',
        'Time is Up , but you can resume',
        'warning'
      )
    }
  }, [timeRemaining]);

  // useEffect(() => {
  //   if (timeRemaining === 0) {
  //     toast.warning('OOOPS , The Time is End ', {
  //       onClose: () => {
  //         generateAnswerTable();
  //       }

  //     });
  //   }
  // }, [timeRemaining]);

  const generateAnswerTable = () => {
    <MakingAnswerTable questions={questions} />
    navigate("/SeeTheAnswerTable");
  };



  // const formatTime = (seconds) => {
  //   if (seconds > 0) {
  //     const minutes = Math.floor(seconds / 60);
  //     const remainingSeconds = seconds % 60;
  //     return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  //   } else {

  //     return "<h1>Time's up!</h1>";
  //   }
  // };
  const formatTime = (seconds) => {
    if (seconds > 0) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    } else {
      return "<h5 style='color: red; font-weight: bold;'>Time's up!</h5>";
    }
  };

  useEffect(() => {
    setUserAnswers({})
    setShowAnswer(false)
    setProgress(0)
    // setTimeRemaining()
    
    async function getQuestions(id) {

      try {
        let endpoint = "";
        if (id.startsWith("T")) {
          const topicId = parseInt(id.match(/\d+/)[0]);
          endpoint = `${Base_url}/PMP/topics/${topicId}`;
          setContinue(true)
        } else if (id.startsWith("E")) {
          const examId = parseInt(id.match(/\d+/)[0]);
          endpoint = `${Base_url}/PMP/exams/${examId}`;
          setContinue(true)

        } else {
          setContinue(false)

        }

        const response = await axios.get(endpoint);
        const data = await response.data;
        setQuestions(data);
        const remainingTime = Math.ceil(data.questions.length * 70); // Calculate remaining time in minutes (rounded up)
       console.log(data.questions.length);
        setTimeRemaining(remainingTime);
        // setTimeRemaining(5)
      } catch (error) {
        // Handle error
      }


    }

    async function fetchData() {
      await getQuestions(id);
      setQuestionLoading(false);
    }

    fetchData();
  }, [id]);



  useEffect(() => {
    setIntroducPage(true);
  }, [id]);




  // const handleAnswerSelection = (questionText, answerId,questionType) => {
  //   // alert("-1")


  //   if(questionType=="MC"){
  //     let item=sessionStorage.getItem(questionText)

  //     if (item==null) {
  //       let listItems=[answerId]        
  //       sessionStorage.setItem(questionText,JSON.stringify(listItems));
  //     }
  //     else if(Array.isArray(JSON.parse(item))){
  //       item=JSON.parse(item)
  //       item.push(answerId)

  //       sessionStorage.setItem(questionText,(item));
  //     }
  //     else{
  //       console.log("else not Array");
  //     }
  //   }
   
  //   else{
  //     sessionStorage.setItem(questionText,answerId);
  //     setUserAnswers(prevState => ({
  //       ...prevState,
  //       [questionText]: answerId
  //     }));
  //   }

  // }
  const handleAnswerSelection = (questionText, answerId,questionType) => {
    // alert("-1")


    if(questionType=="MC"){
      let item=sessionStorage.getItem(questionText)

      if (item==null) {
        let listItems=[answerId]        
        sessionStorage.setItem(questionText,JSON.stringify(listItems));
      }
      else if(Array.isArray(JSON.parse(item))){
        item=JSON.parse(item)
        item.push(answerId)

        sessionStorage.setItem(questionText,JSON.stringify(item));
      }
      else{
        console.log("else not Array");
      }
    }
   
    else{
      sessionStorage.setItem(questionText,JSON.stringify(answerId));
      setUserAnswers(prevState => ({
        ...prevState,
        [questionText]: answerId
      }));
    }

  }

  const highlightAnswer = (answer) => {
    if (showAnswer) {
      if (answer.is_correct) {
        return "border-success";
      } else {
        return "border-danger";
      }
    } else {
      return "";
    }
  }

  const seeAnswers = () => {
    setShowAnswer(true);
    setUserAnswers(answers => ({
      ...answers,
      [questions.questions[currentQuestion].id]: questions.questions[currentQuestion].answers.find(answer => answer.is_correct)?.id
    }));

  }






  const [translatedTexts, setTranslatedTexts] = useState([]);

  const translateText = async (question, answers) => {
    setTranslatedTexts([])
    try {
      const translationPromises = [];
      translationPromises.push(axios.get(`${Base_url}/translate/${question}`));
      answers.forEach(answer => {
        translationPromises.push(axios.get(`${Base_url}/translate/${answer}`));
      });

      const translations = await Promise.all(translationPromises);
      const translatedTexts = translations.map(response => response.data.data);
      setTranslatedTexts(translatedTexts);
    } catch (error) {
      console.error(error);
    }
  };

  const courseInfo = JSON.parse(localStorage.getItem("CourseInfo"));



  return (



    <div className="exam-parent col-12 col-lg-8 " > 
      <SeeAllQuestion questions={questions} />
      <SeeTheTransltion translatedTexts={translatedTexts} />

      {IntroducPage ?
        <div className="introductions">
          <div className="top container d-flex w-100">
            <p className="time-introduc" >Based On The Exam</p>
            <p className="ques-introduc" >+ 50 Question</p>
            <p className="req-scroe-introduc">75% Score Requier to Pass</p>
          </div>

          <div className="middle my-5">
            <h3>Introductions : </h3>
            <ol>
              <li>{courseInfo.Introduction_1?courseInfo.Introduction_1:""}</li>
              <li>{courseInfo.Introduction_2?courseInfo.Introduction_2:""}</li>
              <li>{courseInfo.Introduction_3?courseInfo.Introduction_3:""}</li>
              <li>{courseInfo.Introduction_4?courseInfo.Introduction_4:""}</li>
            </ol>

          </div>
          {timeRemaining ?
            <div className="bottom">
              <button className='btn btn-lg btn-primary' onClick={() => setIntroducPage(false)}>Continue</button>
            </div>

            :
            <div className="bottom">
              <p>Chose Exam or Topic Firts</p>
            </div>
          }

        </div>
        :
        <div className="exam bg-white text-dark" >
          <ToastContainer />
          <div className="tools" >
            {/* <p>{timeRemaining}</p> */}
            {/* <div className="time-remin my-2"> <FontAwesomeIcon icon={faHourglassHalf} size='2x'></FontAwesomeIcon> Time Remaining = <span>{formatTime(timeRemaining)} </span></div> */}
            <div className="time-remin my-2">
  <FontAwesomeIcon icon={faHourglassHalf} size='2x' />
  Time Remaining = <span dangerouslySetInnerHTML={{ __html: formatTime(timeRemaining) }}></span>
</div>
            {/* dangerouslySetInnerHTML={{ __html: formatTime(timeRemaining) }} */}
            {/* <div className="time-remin my-2"> <FontAwesomeIcon icon={faHourglassHalf} size='2x'></FontAwesomeIcon> Time Remaining = <span>{formatTime(timeRemaining)} </span></div> */}
            {/* 
            <div className="progress" style={{ height: "25px" }}>
              <div className="progress-bar" role="progressbar" style={{ width: progress ? progress + "%" : "1%" }} aria-valuenow={progress + "%"} aria-valuemin="0" aria-valuemax="100">{progress}</div>
            </div> */}
            <br />

            <div className='my-2' > <span className='mx-2 px-1'>{currentQuestion}/{questions?.questions?.length - 1}</span><MakingAnswerTable questions={questions} type="icon" /> </div>



          </div>

          {Questionloading ? <p>Loading</p>

            :

            <>
              <div className="question my-3" style={{ fontSize: "1.1rem" }}>{questions.questions[currentQuestion].text.replace(/^#\d{1,2}\.\s/, "")}
              <br></br>
              {questions.questions[currentQuestion].img?<img src={questions.questions[currentQuestion].img} width={"100%"} height={200}/>:""}

</div>

              <div className="answers container mx-3">
                <form action="">
                  {questions.questions[currentQuestion].answers.map(answer => {
                    return (
                      <div className={`form-check ${highlightAnswer(answer)}`} key={answer.id} style={{ minHeight: "60px",height:"auto" }}>
                        {questions.questions[currentQuestion].question_type === "OC" ? (
                          
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id={answer.id}
                            onChange={() => handleAnswerSelection(questions.questions[currentQuestion].text, answer.text,"OC")}
                            checked={userAnswers[questions.questions[currentQuestion].text] === answer.text}
                          />) : (
                          <input className="form-check-input" type="checkbox" name="flexCheckDefault" id={answer.id}
                          onChange={function(){ 
                           handleAnswerSelection(questions.questions[currentQuestion].text, answer.text,"MC")
                         
                          }
                          }
                        //  checked={userAnswers[questions.questions[currentQuestion].text] === 1}
                          />
                          
                        )}
                        <label className="form-check-label" htmlFor={answer.id}>{answer.text}</label>
                        <br></br>
                        {answer.img?<img src={answer.img} width={"100%"} height={200}/>:""}
                      </div>
                    )
                  })}
                </form>
              </div>
            </>

          }



          <div className="submition my-2 mx-2">
            <div className="top">
              <div className="left-top">
                <button type="button" className='btn btn-sm btn-primary' data-mdb-toggle="modal" data-mdb-target="#exampleModal"><FontAwesomeIcon icon={faList} size='2x' /> See All Questions</button>
              </div>
              <div className="middle-top">
              <button className='btn btn-sm btn-dark' data-mdb-toggle="modal" data-mdb-target="#exampleModal3"  onClick={() => translateText(questions.questions[currentQuestion]?.text,questions.questions[currentQuestion]?.answers.map(answer => answer.text))}><FontAwesomeIcon icon={faLanguage} size='2x' /> See Translate</button>             
              {/* <button className='btn btn-sm btn-dark' onClick={() => translateText([questions.questions[currentQuestion]?.text,
                 ...questions.questions[currentQuestion]?.answers.map(answer => answer.text)])}><FontAwesomeIcon icon={faLanguage} size='2x' /> See Translate</button>                <button className='btn btn-sm btn-dark' onClick={()=>translateText(questions.questions[currentQuestion]?.text)}><FontAwesomeIcon icon={faLanguage} size='2x' /> See Translate</button> */}

              </div>
              <div>

                <SeeTheExpalition explanation={questions.questions[currentQuestion]?.explanation} />


              </div>


              <div className="right-top">
                <button type="button" className="btn btn-sm btn-warning" data-mdb-toggle="modal" data-mdb-target="#exampleModal2" onClick={() => seeAnswers()}>
                  <FontAwesomeIcon icon={faCheck} size='2x' /> See Answer
                </button>
              </div>
            </div>

            {/* <div className="bottom-right py-2">
              <button className='btn btn-danger' onClick={() => {
                setCurrentQuestion(prevQuestion => prevQuestion - 1);
                setShowAnswer(false);
              }} disabled={currentQuestion <= 0 || timeRemaining < 0}>
                <FontAwesomeIcon icon={faArrowLeft} />Back
              </button>
              {currentQuestion >= questions.questions.length - 1 ? (
                <MakingAnswerTable questions={questions} type="button" />
              ) : (
                <button className='btn btn-danger' onClick={() => {
                  setCurrentQuestion(prevQuestion => prevQuestion + 1);
                  setShowAnswer(false);
                }} disabled={timeRemaining < 0}>
                  <FontAwesomeIcon icon={faArrowRight} />Next
                </button>
              )}
            </div> */}
       <div className="bottom-right py-2">
              <button className='btn btn-danger' onClick={() => {
                setCurrentQuestion(prevQuestion => prevQuestion - 1);
                setShowAnswer(false);
              }} >
                <FontAwesomeIcon icon={faArrowLeft} />Back
              </button>
              {currentQuestion >= questions.questions.length - 1 ? (
                <MakingAnswerTable questions={questions} type="button" />
              ) : (
                <button className='btn btn-danger' onClick={() => {
                  setCurrentQuestion(prevQuestion => prevQuestion + 1);
                  setShowAnswer(false);
                }}>
                  <FontAwesomeIcon icon={faArrowRight} />Next
                </button>
              )}
            </div>
          </div>

        </div>
      }



    </div>


  )
}
