import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import AnimatedPage from '../AnimatedPage/AnimatedPage';

import Instructor_card from './Instructor_card';
import PlaceHolder from '../Courses/placeholder';
import "./instructor.css"
import { UserDataContext } from '../UserContext/UserContext'

export default function Instructors() {
    const [instructors, setInstructors] = useState([]);
    const [loading, setLoading] = useState(true);
    let {Base_url}=useContext(UserDataContext)

    useEffect(() => {
        async function fetchInstructors() {
            try {
                const response = await axios.get(`${Base_url}/instructors/`);
                setInstructors(response.data);
                setLoading(false);
            } catch (error) {
                setLoading(true);
            }
        }
        fetchInstructors();
    }, [])



   
    return (
        <>
            {/* <Navbar /> */}
            <AnimatedPage>
            <div id="instructor" className='instructors-parent  p-5'data-aos="fade-up" >   <h2 className='text-center my-4 p-5 text-dark'>Our Instructors</h2>
                    {loading ? (
                        <div className='d-flex justify-content-center align-items-center'>
                                                    <PlaceHolder/>

                        </div>
                    ) : (
                    <Instructor_card  instructors={instructors}/>
                    )}
                </div>

            </AnimatedPage>
        </>
    );
}