import React from 'react'

export default function SeeAllQuestion(props) {
  return (
    <div  >
            <div>
            {/* Button trigger modal */}
         
            {/* Modal */}
            <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                <div className="modal-content"  style={{maxHeight:"400px",overflowY:"scroll"}}>
                    <div className="modal-header">
                    <h5 className="modal-title text-dark" id="exampleModalLabel">All Questions</h5>
                    <button type="button" className="btn-close" data-mdb-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className="modal-body text-dark">
                {props.questions?.questions?.map((question, index) => (
                  <div key={question.id}>
                    <h6>{index + 1}. {question.text}</h6>
                    <ol type='I'>
                      {question.answers.map(answer => (
                        <li key={answer.id}>{answer.text}</li>
                      ))}
                    </ol>
                    <hr></hr>
                  </div>
                ))}
              </div>
                    <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-mdb-dismiss="modal">Close</button>
                    </div>
                </div>
                </div>
            </div>
            </div>


    </div>
  )
}
