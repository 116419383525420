import React,{useEffect,useState} from 'react'
import Login from './Components/Login/Login'
import Home from './Components/Home/Home'
import "./style/style.css"
import ContactUs from './Components/ContactUs/ContactUs'
import AboutsUs from './Components/AboutUs/AboutsUs'
import Instructors from './Components/Instructors/Instructors'
import Lectuers from "./Components/Lectuers/Lectuers"
import UserContext from "./Components/UserContext/UserContext";

import { Route, Routes } from 'react-router-dom'
import Checkout from './Components/Checkout/Checkout'
import Exam from './Components/Exam/Exam'
import NotFound from './Components/404/404'
import { AnimatePresence } from "framer-motion";
import ExamTemplate from './Components/Exam/ExamTemplate'
import Content from './Components/Lectuers/Content'

import 'react-toastify/dist/ReactToastify.css';
import AllLectuers from './Components/Lectuers/AllLectuers'
import SeeTheAnswerTable from './Components/Exam/Modals/SeeTheAnswerTable'
import Courses from './Components/Courses/Courses'

import AOS from "aos";
import "aos/dist/aos.css";
import Splash from './Components/splash/Spalsh'
import ForgetPassword from './Components/Login/Form/ForgetPasswordForm'
import NewPasswordForm from './Components/Login/Form/NewPasswordForm'
import ForgetPasswordForm from './Components/Login/Form/ForgetPasswordForm'
// import Checkout_new from './Components/Checkout/Checkout_new'
// import PayPalCheckout from './Components/Checkout/PayPalCheckout'

export default function App() {

   let [slpash,setSplash]=useState(true)




   setTimeout(()=>{
     setSplash(false)
   },100)
 


   useEffect(() => {
      AOS.init({
        duration: 1200, // Animation duration in milliseconds
        once: false, // Whether the animation should only happen once
        mirror: false, // Whether elements should animate out while scrolling past them
      });
      AOS.refresh();
    }, []);

  return (
   <>
  
<UserContext>

<AnimatePresence mode='wait'>
{slpash?

   <Splash/>
:

   <Routes>
      <Route element={<Home  />} path=''/>

      <Route element={<ContactUs />} path='/ContactUs'/>

      <Route element={<Instructors />} path='/Instructors'/>

      <Route element={<AboutsUs />} path='/AboutUs'/>

      <Route element={<Courses />} path='/Courses'/>

      <Route element={<Login />} path='/Login'/>

      <Route element={<ForgetPasswordForm/>} path="/password/reset/send" /> {/** Send an Email*/}
      <Route element={<NewPasswordForm/>} path="/password/reset/confirm/:otp" />    {/** Create new password */}


      <Route element={<Lectuers />} path='/Lectuers/:id'>
       <Route element={<Content  />} path='/Lectuers/:id/:id' />

      </Route>

      <Route element={<Exam />} path='/Exam'>
         <Route element={<ExamTemplate  />} path='/Exam/:id' />
         </Route>

      <Route element={<Checkout />} path='/Checkout/:id'/>

      {/* <Route element={<Checkout_new/>} path="/Checkout/:id/" /> */}

      <Route element={<AllLectuers />} path='/Course_Lectuers/:id'/>

      <Route element={<SeeTheAnswerTable />} path='SeeTheAnswerTable/' />

      {/* <Route element={<PayPalCheckout/>} path='PayPalCheckout/' /> */}


      <Route path="*" element={<NotFound/>}/>

   </Routes>

}
   
   </AnimatePresence>


  </UserContext>


   </>
  )
}
// after production