import React, { useEffect, useRef } from 'react';
import Intro from "../intro2.mp4";

export default function Splash() {
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    // Play the video once it's loaded
    videoElement.addEventListener('loadeddata', () => {
      videoElement.play();
    });

    // When the component unmounts, remove the event listener
    return () => {
      videoElement.removeEventListener('loadeddata', () => {
        videoElement.play();
      });
    };
  }, []);

  return (
    <div className=''style={{background:"#ECEBEC"}}>
      <video ref={videoRef}  className='w-100' style={{height:"99vh",width:"100vw",objectFit:"cover"}} autoPlay loop muted>
        <source src={Intro} type="video/mp4" />
      </video>
    </div>
  );
}