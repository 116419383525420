import React, { useContext, useEffect, useState } from 'react'
import ReactPlayer from 'react-player'

import { Link, useParams } from 'react-router-dom';
import axios from 'axios'
import { faDotCircle } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AnimatedPage from '../AnimatedPage/AnimatedPage';

import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

import "./checkout.css"
import Navbar_two from '../Navbar/Navbar2';
import Checkout_new from './Checkout_new';

import Image from "./soon3.jpg"
import Navbar from '../Navbar/Navbar';



import { UserDataContext } from '../UserContext/UserContext'




export default function Checkout() {
  

    let {Base_url}=useContext(UserDataContext)

    const navigate = useNavigate();

    let token=localStorage.getItem("Token")
    const isLogged = ! (token === null || token === "undefined" || token === "");

    // course id
    const { id } = useParams();
  
    const [course_infos, setCourseInfos] = useState({});
  
    const user_id = parseInt(localStorage.getItem("User_id"))
  
    const [loading, setLoading] = useState(false);
  
    const [paidChecking, setPaidChecking] = useState(false);
    const [orderData, setOrderData] = useState({});
  
    let [country,setCountry]=useState("")

    let [price,setPrice]=useState()
    useEffect(() => {
      async function fetchData() {
        try {
          setLoading(true);
          const response = await axios.get(`${Base_url}/courses/${id}`);
          const course_data = await response.data;
          setCourseInfos(course_data);
          setLoading(false);
          localStorage.setItem("CourseInfo", JSON.stringify(course_data));
    
          setOrderData({
            "course": course_data.id,
            "user": user_id
          });
    
          // Call CheckPaidorNot after setting course_data
          CheckPaidorNot(course_data.id, user_id);
        } catch (error) {
          toast.warning("OOOPS , Something went wrong");
        }
      }
    
      async function CheckPaidorNot(course_id, user_id) {
        try {
          const response = await axios.get(`${Base_url}/check_paid_course/${user_id}/${course_id}/`);
          response.data.paid ? setPaidChecking(true) : setPaidChecking(false);
        } catch (error) {
          toast.warning("OOOPS , Something went wrong");
        }
      }


      fetchData();
    }, [id]);
    
    useEffect(()=>{
      async function getIpAddress(){
        try{
          let response= await axios.get("https://get.geojs.io/v1/ip")
          let r1=response.data;
          // let r1="2001:16a4:17:95c8:85a4:4be4:e3f3:f3d3"
          // let r1="95.181.235.158"
          // let r1="197.54.229.82"
          let responseCountry =await axios.post(`https://get.geojs.io/v1/ip/country/${r1}`)
  
        //  console.log(responseCountry.data=="SA\n");
        setCountry(responseCountry.data)
        // console.log(responseCountry.data);


         
        }
        catch{
          setCountry("other")
        }
      }


   


      // async function RateExchange(){
      //   try{

      //    await fetch("https://api.currencyapi.com/v3/latest?apikey=cur_live_H5CAOfA22EFQBYSxCNybZwjElcqorW4Zd4XGecri")
      //     .then((result) => {
      //       let myData = result.json();

      //       return myData;
      //     })
      //     .then((currency) => {
      //       let amount =parseInt(course_infos.price);

      //       if(country=="EG\n"){
      //         console.log(currency.rates["EGP"]);
      //         // setPrice( Math.round(amount * currency.rates["EGP"])+"  LE")
      //         setPrice(amount+" E")
      //       }
      //       else if(country=="SA\n"){
      //         setPrice(Math.round(amount * currency.rates["SAR"]))
      //         console.log(Math.round(amount * currency.rates["SAR"]));
      //     } 
      //     else{
      //       console.log(country)
      //       console.log("europe");
      //       setPrice(parseInt(course_infos.price) + " $")


      //     }
        
      //     })
      //     .catch((error)=> {

      //       setPrice(parseInt(course_infos.price) + " $")
      //     })
  
      //   }
      //   catch{
      //     setPrice(parseInt(course_infos.price) + " $")

      //     toast.warning("OOOPS , Something went wrong");
      //   }
      //        } 
      getIpAddress()
      // RateExchange();
    },[country])
 
    useEffect(()=>{
      async function RateExchange(){
       
        if(country=="EG\n"){         
          setPrice(parseInt(course_infos.price_LE)+" LE")
        }
        else if(country=="SA\n"){
          setPrice(parseInt(course_infos.price_SR)+" SAR")
      } 
      else{
        setPrice(parseInt(course_infos.price) + " $")
      }
    } 
    RateExchange()
    })


    //  useEffect(()=>{
      
    //   window.paypal
    //   .Buttons({
    //     async createOrder() {
    //       try {
    //         const response = await fetch(`${Base_url}/create-payment/`, {
    //           method: "POST",
    //           headers: {
    //             "Content-Type": "application/json",
    //           },
    //           body: JSON.stringify({
    //             cart: [
    //               {
    //                 id:course_infos.id ,//"YOUR_PRODUCT_ID"
    //                 quantity: 1,
    //                 value:parseInt(price),
    //                 country:country
    //               },
    //             ],
    //           }),
    //         });
    
    //         const orderData = await response.json();
    
    //         if (orderData.id) {
    //           return orderData.id;
    //         } else {
    //           const errorDetail = orderData?.details?.[0];
    //           const errorMessage = errorDetail
    //             ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
    //             : JSON.stringify(orderData);
    
    //           throw new Error(errorMessage);
    //         }
    //       } catch (error) {
    //         console.error(error);
    //         resultMessage(`Could not initiate PayPal Checkout...<br><br>${error}`);
    //       }
    //     },
    //     async onApprove(data, actions) {
    //       try {
    //         const response = await fetch(`${Base_url}/orders/${data.orderID}/`, {
    //           method: "POST",
    //           headers: {
    //             "Content-Type": "application/json",
    //           },
    //         });            
    //         const orderData = await response.json();
    
    //         const errorDetail = orderData?.details?.[0];
    
    //         if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
    //            return actions.restart();
    //         } else if (errorDetail) {
    //           throw new Error(`${errorDetail.description} (${orderData.debug_id})`);
    //         } else if (!orderData.purchase_units) {
    //           throw new Error(JSON.stringify(orderData));
    //         } else {
    //           const transaction =
    //             orderData?.purchase_units?.[0]?.payments?.captures?.[0] ||
    //             orderData?.purchase_units?.[0]?.payments?.authorizations?.[0];
    //           resultMessage(
    //             `Transaction ${transaction.status}: ${transaction.id}<br><br>See console for all available details`,
    //           );
    //           orderSubmiting(orderData)

    //           console.log(
    //             "Capture result",
    //             orderData,
    //             JSON.stringify(orderData, null, 2),
    //           );
    //         }
    //       } catch (error) {
    //         console.error(error);
    //         toast.warning('Sorry, your transaction could not be processed..')
    //       }
    //     },
    //   })
    //   .render("#paypal-button-container");
  
    //   function resultMessage(message) {
    //     const container = document.querySelector("#result-message");
    //     container.innerHTML = message;
    //   }
    // },[price])

    useEffect(() => {
      const timer = setTimeout(() => {
        window.paypal
          .Buttons({
            async createOrder() {
              try {
                const response = await fetch(`${Base_url}/create-payment/`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    cart: [
                      {
                        id: course_infos.id, //"YOUR_PRODUCT_ID"
                        quantity: 1,
                        value: parseInt(course_infos.price),
                        country: country,
                      },
                    ],
                  }),
                });
                console.log(price);
                console.log(country)
  
                const orderData = await response.json();
  
                if (orderData.id) {
                  return orderData.id;
                } else {
                  const errorDetail = orderData?.details?.[0];
                  const errorMessage = errorDetail
                    ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                    : JSON.stringify(orderData);
  
                  throw new Error(errorMessage);
                }
              } catch (error) {
                console.error(error);
                resultMessage(`Could not initiate PayPal Checkout...<br><br>${error}`);
              }
            },
            async onApprove(data, actions) {
              try {
                const response = await fetch(`${Base_url}/orders/${data.orderID}/`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                });
                const orderData = await response.json();
  
                const errorDetail = orderData?.details?.[0];
  
                if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                  return actions.restart();
                } else if (errorDetail) {
                  throw new Error(`${errorDetail.description} (${orderData.debug_id})`);
                } else if (!orderData.purchase_units) {
                  throw new Error(JSON.stringify(orderData));
                } else {
                  const transaction =
                    orderData?.purchase_units?.[0]?.payments?.captures?.[0] ||
                    orderData?.purchase_units?.[0]?.payments?.authorizations?.[0];
                  resultMessage(
                    `Transaction ${transaction.status}: ${transaction.id}<br><br>See console for all available details`,
                  );
                  orderSubmiting(orderData);
  
                  console.log(
                    "Capture result",
                    orderData,
                    JSON.stringify(orderData, null, 2),
                  );
                }
              } catch (error) {
                console.error(error);
                toast.warning('Sorry, your transaction could not be processed..');
              }
            },
          })
          .render("#paypal-button-container");
  
        function resultMessage(message) {
          const container = document.querySelector("#result-message");
          container.innerHTML = message;
        }
      }, 1000);
  
      return () => {
        clearTimeout(timer);
      };
    }, [country]);
  
  
    const orderSubmiting = async ({ id, status, purchase_units }) => {
      setOrderData({});
      try {
        const response = await axios.post(`${Base_url}/orders/`, {
          ...orderData,
          "order_id": parseInt(id),
          "order_status": status,
          "amount":price,
          // "amount": parseInt(purchase_units[0]['payments']['captures'][0]['amount']['value']),
          "user": user_id
        });
      

        toast.success('Your Order Success , will Navigate Automatically To Lessons Page ', {
          onClose: () => {
            navigate(`/Course_Lectuers/${course_infos.id}/`);
          }
        });
      } catch (error) {
        console.log("error")
        toast.warning("OOOPS , Some Thing Wrong");
      }
  
  // Example function to show a result to the user. Your site's UI library can be used instead.


    };



    const parsedLessons = course_infos?.lessons?.map((lesson) => {
      const match = lesson.name.match(/Lec (\d+)/); // Extract the number after "Lec"
      const lectureNumber = match ? parseInt(match[1]) : 0; // Convert the number to integer
      return {
        lesson,
        lectureNumber,
      };
    });
    const sortedLessons = parsedLessons?.sort((a, b) => a.lectureNumber - b.lectureNumber);



    return (

    
      
   
    
      <div>

        <AnimatedPage>
          <ToastContainer/>
          <Navbar_two/>

          {course_infos.status=="Soon"?
      <div className='d-flex container'>

      <img src={Image} width={"100%"}  style={{height:"87.2vh",justifyContent:"center"}} />

      </div>
      :
      <>

      <div className="container">
            {loading ?
              <h3 className='text-center my-5'>Loading .....</h3>
              :
              <div className="checkout-parent">
                <div className="video-parent col-md-7 col-12">
                  <ReactPlayer width={"100%"} height={"100%"}
                    config={{
                      file: {
                        attributes: {
                          controlsList: 'nodownload' // Prevent downloading the video
                        }
                      }
                    }} url={course_infos.trailer} controls />
                </div>
                <div className="payemnt-parent col-md-4 col-12">
                  <div className="course-info p-3">
                    <div className="course-infos-top d-flex justify-content-between">
                      <h5> <span className='fw-bold'>Course</span>: {course_infos.name} </h5>
                      <br></br>
                      </div>
                      
  
                      <h5 className='fw-bold'>Price : <span className="amount"> {price} </span> </h5>
                     

                 
                    
                    <hr style={{ "width": "50%" }} />
                    <h5> <span className='fw-bold'>Instructor</span> :  {course_infos?.instructor?.name}</h5>
                  
                    <hr style={{ "width": "50%" }} />

                    {paidChecking ? (
  <Link className='btn btn-primary btn-block' to={`/Course_Lectuers/${course_infos.id}`}>
    GO TO Course Direct
  </Link>
) : (
  isLogged ? (
    <>
        {country=="EG\n"?
          <div class="alert alert-warning" role="alert">
          If you are located in Egypt, beacuse what is the Central Egyptian Bank did to block transactions with unlocal currencies.

You can transfer your amount using InstaPay to: AhmedHamed.97
<br></br>

or traditional banking transfer to: (Arab Bank): 5015564522419,
<br></br>
IBAN: EG460044501500005015564522419
<br></br>
or Vodafone cash to: 01030454662

<br></br>
<p className='fw-bold'>Send screenshot for the Transaction to 01140460466</p>

        </div>
      :""}
                
                  <div id="paypal-button-container"></div>
                  <p id="result-message"></p>


    </>
  ) : (
    <button className='btn btn-primary btn-block' onClick={() => navigate("/login")}>
      Log in to Proceed
    </button>
  )
)}
                  </div>
                </div>
              </div>
            }
          </div>
          <hr></hr>
      <div className="container description my-2 py-2">
      <h5 className='fw-bold'>Lessons : </h5>
                    <ul>
                      {sortedLessons?.map((e) =>
  <li key={e.id}><FontAwesomeIcon icon={faDotCircle} className='text-muted mx-2'></FontAwesomeIcon> <span >{e.lesson.name}</span> </li>
                      )}
                    </ul></div>    
      </>

      
      }


            {course_infos.status=="Soon"?"":
                 <div className='description my-5 container p-2' style={{maxHeight:"300px",overflowY:"scroll",overflowX:"hidden"}}>
                 <h5 className='fw-bold text-center'>Descritption <br></br> </h5>
                 <hr></hr>
                 <span className="price fw-bold" dir="rtl" >
                   {course_infos.description?.split("Module (")?.map((e)=><p>{e}</p>
                 
                 )}</span>
               </div>
            
            }
     
        </AnimatedPage>
      </div>
    );
  }