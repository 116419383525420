import React, { useState, useEffect } from 'react';

export default function SeeTheTransltion({ translatedTexts }) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (translatedTexts) {
      setLoading(false);
    }
  }, [translatedTexts]);

  return (
    <div>
      <div className="modal fade" id="exampleModal3" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-dark" id="exampleModalLabel">{loading?"loading":"Translaition"}</h5>
              <button type="button" className="btn-close" data-mdb-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body text-dark">
              {loading ? (
                <h2 className='text-dark'>Loading...</h2>
              ) : (
                translatedTexts.map((text, index) => (
                  <React.Fragment key={index}>
                    {index === 0 ? (
                      <h5 style={{ fontWeight: "bold", textAlign: "center" }}>{text}</h5>
                    ) : (
                      <ul style={{ textAlign: "right" }}>
                        <li key={index}>{text}</li>
                      </ul>
                    )}
                    {index !== 0 && <hr />}
                  </React.Fragment>
                ))
              )}
            </div>


          </div>
        </div>
      </div>
    </div>
  );
}