import React, { useContext, useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import Film from "./film.mp4"
import pdffile from "./file.pdf"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import AnimatedPage from '../AnimatedPage/AnimatedPage';
import { UserDataContext } from '../UserContext/UserContext'


export default function Content(props) {

    let{id}=useParams()    // const [file, setFile] = useState(pdffile);
    let {Base_url}=useContext(UserDataContext)

    let[data,setData]=useState({})
    let [loading,setLoading]=useState(true)

    const url = window.location.href;
    const parts = url.split('/');
    const course_id = parts[4];



    useEffect(() => {
        async function fetchData() {
          try {
            const response = await axios.get(`${Base_url}/courses/${course_id}/${id}`);
            const lesson_data = await response.data;
            setData(lesson_data);
            setLoading(false)
          } catch (error) {
            // Handle error here
          }
        }
        fetchData();
      }, [id]);

    const handleDownloadPdf = () => {
      window.open(data.pdf_file, '_blank');
    };

  return (
    <AnimatedPage>
        {loading?<p>Loading ......</p>:data.name}
    <ReactPlayer width={"100%"} height={"400px"}
      config={{
        file: {
          attributes: {
            controlsList: 'nodownload' // Prevent downloading the video
          }
        }
      }} url={data.video_file} controls />




    <div className="d-flex text-center justify-content-center my-4">

      <button onClick={handleDownloadPdf} className='btn btn-primary btn-lg'>Download PDF <FontAwesomeIcon icon={faDownload}/></button>

    </div>

    <hr /> 
    
    
    </AnimatedPage>
  )
}
